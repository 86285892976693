import React from "react";
import style from "./App.module.css";

function App() {
  return (
    <div id={style.bg}>
    This app currently under development...
    </div>
  );
}

export default App;
